import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationES from "./locales/es/translation.json";
import translationCA from "./locales/ca/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationDE from "./locales/de/translation.json";
import translationEN from "./locales/en/translation.json";

const resources = {
  es: {
    translation: translationES,
  },
  ca: {
    translation: translationCA,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  en: {
    translation: translationEN,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "ca",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator', 'cookie', 'querystring', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie'],
    },
  });

export default i18n;
