import { isArray, isObject } from "lodash";
import moment from "moment";

export const formatCustomerFeedbacks = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};
export const formatBookings = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.bookingNumber}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.bookingNumber}`,
      date: data.date && moment(data.date),
    };
  }
};
export const formatIncidences = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    };
  }
};
export const formatWorkorders = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};
export const formatElements = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};
export const formatElementtypes = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};
export const formatUsers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: `${data.name} ${data.surname}`,
      value: data._id,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name} ${data.surname}`,
    };
  }
};

export const formatBeachs = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};

export const formatAlerts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: `${data.title}`,
      value: data._id,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
    };
  }
};

export const formatServices = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.number}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.number}`,
    };
  }
};
