import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_SERVICE,
  DELETE_SERVICE,
  SERVICE_FORM_TOOGLE_LOADING,
  SET_SERVICE_LIST,
  UPDATE_SERVICE,
} from "../constants";
import { formatServices } from "./settingsActionsUtils";

/* SERVICE LIST */
export const fetchServices = (dates) => {
  return async (dispatch) => {
    const response = await axios
      .patch(API.services.list, dates)
      .then((response) => {
        const services = formatServices(response.data);
        dispatch({
          type: SET_SERVICE_LIST,
          payload: keyBy(services, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

export const fetchServicesNoAuth = (dates) => {
  return async (dispatch) => {
    const response = await axios
      .patch(API.services.listnoauth, dates)
      .then((response) => {
        const services = formatServices(response.data);
        dispatch({
          type: SET_SERVICE_LIST,
          payload: keyBy(services, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// SERVICES
export const createService = (newService) => {
  return async (dispatch) => {
    dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.services.create, newService)
      .then((response) => {
        const service = formatServices(response.data);
        dispatch({ type: ADD_NEW_SERVICE, payload: service });
        dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateService = (updatedService) => {
  return async (dispatch) => {
    dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.services.edit}${updatedService?._id}`, updatedService)
      .then((response) => {
        const service = formatServices(response.data);
        dispatch({ type: UPDATE_SERVICE, payload: service });
        dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const fetchNoInBeachService = (_id) => {
  return async (dispatch) => {
    dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
    const response = await axios
      .get(`${API.services.noinbeach}${_id}`)
      .then((response) => {
        const service = formatServices(response.data);
        response.data = service;
        return response;
      })
      .catch((err) => {
        dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const blockService = (updatedService) => {
  return async (dispatch) => {
    dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.services.block}${updatedService?._id}`, updatedService)
      .then((response) => {
        const service = formatServices(response.data);
        dispatch({ type: UPDATE_SERVICE, payload: service });
        dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
export const unblockService = (updatedService) => {
  return async (dispatch) => {
    dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.services.unblock}${updatedService?._id}`, updatedService)
      .then((response) => {
        const service = formatServices(response.data);
        dispatch({ type: UPDATE_SERVICE, payload: service });
        dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteServices = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.services.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_SERVICE, payload: Ids });
        dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: SERVICE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
