import {
  HomeOutlined,
  AreaChartOutlined,
  LoginOutlined,
  LogoutOutlined,
  UserOutlined,
  EuroCircleOutlined,
  LaptopOutlined,
  WarningOutlined,
  ToolOutlined,
  GroupOutlined,
  UnorderedListOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import React from "react";
import { ROLES } from "./permissions";

export const routes = {
  home: {
    key: "home",
    slug: "home",
    to: "/home",
    title: "beaches",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.manager.role, ROLES.operator.role, ROLES.client.role, ROLES.maintenance.role],
  },

  profile: {
    key: "profile",
    slug: "profile",
    to: "/profile",
    title: "profile",
    icon: <UserOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.manager.role, ROLES.operator.role, ROLES.client.role, ROLES.maintenance.role],
  },

  incidences: {
    key: "incidences",
    slug: "incidences",
    to: "/incidences",
    title: "incidences",
    icon: <WarningOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.maintenance.role, ROLES.manager.role, ROLES.operator.role],
  },

  workorders: {
    key: "workorders",
    slug: "workorders",
    to: "/workorders",
    title: "workOrders",
    icon: <ToolOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.maintenance.role, ROLES.manager.role],
  },

  services: {
    key: "services",
    slug: "services",
    to: "/services",
    title: "services",
    icon: <LaptopOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.manager.role],
  },

  bookings: {
    key: "bookings",
    slug: "bookings",
    to: "/bookings",
    title: "bookings",
    icon: <EuroCircleOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.manager.role, ROLES.operator.role, ROLES.client.role, ROLES.maintenance.role],
  },

  login: {
    key: "login",
    slug: "login",
    to: "/login",
    title: "LOGIN",
    icon: <LoginOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.manager.role, ROLES.operator.role, ROLES.client.role, ROLES.maintenance.role],
  },

  elements: {
    key: "elements",
    slug: "elements",
    to: "/elements",
    title: "elements",
    icon: <GroupOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.manager.role],
  },

  elementtypes: {
    key: "elementtypes",
    slug: "elementtypes",
    to: "/elementtypes",
    title: "elementTypes",
    icon: <UnorderedListOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.manager.role],
  },

  estadistics: {
    key: "estadistics",
    slug: "estadistics",
    to: "/estadistics",
    title: "statistics",
    icon: <AreaChartOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.manager.role],
  },

  feedbacks: {
    key: "feedbacks",
    slug: "feedbacks",
    to: "/feedbacks",
    title: "surveys",
    icon: <UserSwitchOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.manager.role, ROLES.operator.role, ROLES.client.role, ROLES.maintenance.role],
  },

  users: {
    key: "users",
    slug: "users",
    to: "/users",
    title: "users",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  business: {
    key: "business",
    slug: "business",
    to: "/business",
    title: "Clientes",
    icon: <UsergroupAddOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  logout: {
    key: "logout",
    slug: "logout",
    to: "/logout",
    title: "logOut",
    icon: <LogoutOutlined />,
    showInMenu: true,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.manager.role, ROLES.operator.role, ROLES.client.role, ROLES.maintenance.role],
  },
};
