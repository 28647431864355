import { handleActions, handleAction } from "redux-actions";
import {
  AUTH,
  SET_PROFILE,
  SET_CURRENT_ROUTE,
  LOGOUT,
  CLOSE_NOTIFICATION,
  SET_SIDE_MENU_STATE,
  OPEN_USERS_FORM,
  CLOSE_USERS_FORM,
  EDIT_SELECTED_USER,
  USERS_FORM_TOOGLE_LOADING,
  SET_USER_LIST,
  ADD_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
  ADD_NEW_BEACH,
  SET_BEACH_LIST,
  UPDATE_BEACH,
  DELETE_BEACH,
  OPEN_BEACH_FORM,
  CLOSE_BEACH_FORM,
  EDIT_SELECTED_BEACH,
  BEACH_FORM_TOOGLE_LOADING,
  ADD_NEW_SERVICE,
  SET_SERVICE_LIST,
  UPDATE_SERVICE,
  DELETE_SERVICE,
  OPEN_SERVICE_FORM,
  CLOSE_SERVICE_FORM,
  EDIT_SELECTED_SERVICE,
  SERVICE_FORM_TOOGLE_LOADING,
  OPEN_ALERT_FORM,
  CLOSE_ALERT_FORM,
  EDIT_SELECTED_ALERT,
  ALERT_FORM_TOOGLE_LOADING,
  SET_ALERT_LIST,
  ADD_NEW_ALERT,
  UPDATE_ALERT,
  DELETE_ALERT,
  THEME,
  OPEN_SERVICE_BOOKFORM,
  CLOSE_SERVICE_BOOKFORM,
  SERVICE_BOOKFORM_TOOGLE_LOADING,
  EDIT_SELECTED_BOOKSERVICE,
  ADD_NEW_BOOKING,
  SET_BOOKING_LIST,
  UPDATE_BOOKING,
  DELETE_BOOKING,
  OPEN_BOOKING_FORM,
  CLOSE_BOOKING_FORM,
  EDIT_SELECTED_BOOKING,
  BOOKING_FORM_TOOGLE_LOADING,
  ADD_NEW_ELEMENTTYPE,
  SET_ELEMENTTYPE_LIST,
  UPDATE_ELEMENTTYPE,
  DELETE_ELEMENTTYPE,
  OPEN_ELEMENTTYPE_FORM,
  CLOSE_ELEMENTTYPE_FORM,
  EDIT_SELECTED_ELEMENTTYPE,
  ELEMENTTYPE_FORM_TOOGLE_LOADING,
  ADD_NEW_ELEMENT,
  SET_ELEMENT_LIST,
  UPDATE_ELEMENT,
  DELETE_ELEMENT,
  OPEN_ELEMENT_FORM,
  CLOSE_ELEMENT_FORM,
  EDIT_SELECTED_ELEMENT,
  ELEMENT_FORM_TOOGLE_LOADING,
  ADD_NEW_WORKORDER,
  SET_WORKORDER_LIST,
  UPDATE_WORKORDER,
  DELETE_WORKORDER,
  OPEN_WORKORDER_FORM,
  CLOSE_WORKORDER_FORM,
  EDIT_SELECTED_WORKORDER,
  WORKORDER_FORM_TOOGLE_LOADING,
  ADD_NEW_INCIDENCE,
  SET_INCIDENCE_LIST,
  UPDATE_INCIDENCE,
  DELETE_INCIDENCE,
  OPEN_INCIDENCE_FORM,
  CLOSE_INCIDENCE_FORM,
  EDIT_SELECTED_INCIDENCE,
  INCIDENCE_FORM_TOOGLE_LOADING,
  ADD_NEW_CUSTOMERFEEDBACK,
  SET_CUSTOMERFEEDBACK_LIST,
  UPDATE_CUSTOMERFEEDBACK,
  DELETE_CUSTOMERFEEDBACK,
  OPEN_CUSTOMERFEEDBACK_FORM,
  CLOSE_CUSTOMERFEEDBACK_FORM,
  EDIT_SELECTED_CUSTOMERFEEDBACK,
  CUSTOMERFEEDBACK_FORM_TOOGLE_LOADING,
  SHOW_NOTIFICATION,
} from "./../../constants";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  {}
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(
  THEME,
  (state, action) => action.payload,
  false
);

export const users = handleActions(
  {
    [SET_USER_LIST]: (state, action) => action.payload,
    [ADD_NEW_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_USER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const usersForm = handleActions(
  {
    [OPEN_USERS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_USER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [USERS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const alerts = handleActions(
  {
    [SET_ALERT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ALERT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const alertsForm = handleActions(
  {
    [OPEN_ALERT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_ALERT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// BEACHS
export const beachs = handleActions(
  {
    [SET_BEACH_LIST]: (state, action) => action.payload,
    [ADD_NEW_BEACH]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_BEACH]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_BEACH]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const beachsForm = handleActions(
  {
    [OPEN_BEACH_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_BEACH_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_BEACH]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [BEACH_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SERVICES
export const services = handleActions(
  {
    [SET_SERVICE_LIST]: (state, action) => action.payload,
    [ADD_NEW_SERVICE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SERVICE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SERVICE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const servicesForm = handleActions(
  {
    [OPEN_SERVICE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SERVICE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SERVICE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SERVICE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const servicesBookForm = handleActions(
  {
    [OPEN_SERVICE_BOOKFORM]: (state, action) => ({
      show: !state.show,
      data: action.payload,
      loading: false,
    }),
    [CLOSE_SERVICE_BOOKFORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_BOOKSERVICE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SERVICE_BOOKFORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// BOOKINGS
export const bookings = handleActions(
  {
    [SET_BOOKING_LIST]: (state, action) => action.payload,
    [ADD_NEW_BOOKING]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_BOOKING]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_BOOKING]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const bookingsForm = handleActions(
  {
    [OPEN_BOOKING_FORM]: (state, action) => ({
      show: !state.show,
      data: action.payload,
      loading: false,
    }),
    [CLOSE_BOOKING_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_BOOKING]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [BOOKING_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ELEMENTTYPES
export const elementtypes = handleActions(
  {
    [SET_ELEMENTTYPE_LIST]: (state, action) => action.payload,
    [ADD_NEW_ELEMENTTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ELEMENTTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ELEMENTTYPE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const elementtypesForm = handleActions(
  {
    [OPEN_ELEMENTTYPE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ELEMENTTYPE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ELEMENTTYPE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ELEMENTTYPE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ELEMENTS
export const elements = handleActions(
  {
    [SET_ELEMENT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ELEMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ELEMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ELEMENT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const elementsForm = handleActions(
  {
    [OPEN_ELEMENT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ELEMENT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ELEMENT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ELEMENT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// WORKORDERS
export const workorders = handleActions(
  {
    [SET_WORKORDER_LIST]: (state, action) => action.payload,
    [ADD_NEW_WORKORDER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_WORKORDER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_WORKORDER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const workordersForm = handleActions(
  {
    [OPEN_WORKORDER_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_WORKORDER_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_WORKORDER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [WORKORDER_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// INCIDENCES
export const incidences = handleActions(
  {
    [SET_INCIDENCE_LIST]: (state, action) => action.payload,
    [ADD_NEW_INCIDENCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_INCIDENCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_INCIDENCE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const incidencesForm = handleActions(
  {
    [OPEN_INCIDENCE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_INCIDENCE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_INCIDENCE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [INCIDENCE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CUSTOMERFEEDBACKS
export const customerFeedbacks = handleActions(
  {
    [SET_CUSTOMERFEEDBACK_LIST]: (state, action) => action.payload,
    [ADD_NEW_CUSTOMERFEEDBACK]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CUSTOMERFEEDBACK]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CUSTOMERFEEDBACK]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const customerFeedbacksForm = handleActions(
  {
    [OPEN_CUSTOMERFEEDBACK_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CUSTOMERFEEDBACK_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CUSTOMERFEEDBACK]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CUSTOMERFEEDBACK_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);
