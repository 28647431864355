import React from 'react';
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter, } from "react-router-dom";
import { ConfigProvider } from "antd";
import es_ES from "antd/es/locale/es_ES";
import MobileConsoleDebug from "./utils/MobileConsoleDebug";
import ConnectionHandler from "./connection/ConnectionHandler";
import NotificationsHandler from "./components/Notifications";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import "./styles/index.css";
import "./assets/fonts/index.less";
import "react-drop-zone/dist/styles.css";
import "./i18n";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<ConfigProvider locale={es_ES}>
				<App />
				<ConnectionHandler />
				<NotificationsHandler />
				<MobileConsoleDebug />
			</ConfigProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
