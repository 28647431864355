import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  beachs,
  beachsForm,
  services,
  servicesForm,
  servicesBookForm,
  currentTheme,
  elementtypes,
  elementtypesForm,
  elements,
  elementsForm,
  workorders,
  workordersForm,
  incidences,
  incidencesForm,
  bookings,
  bookingsForm,
  customerFeedbacks,
  customerFeedbacksForm,
} from "./Reducers";

export default combineReducers({
  customerFeedbacks,
  customerFeedbacksForm,
  bookings,
  bookingsForm,
  incidences,
  incidencesForm,
  workorders,
  workordersForm,
  elements,
  elementsForm,
  elementtypes,
  elementtypesForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  beachs,
  beachsForm,
  services,
  servicesForm,
  servicesBookForm,
  currentTheme,
});
