import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
import { ROLES_RAW } from "../../constants/permissions";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
  (state) => state.authorization,
  (authorization) => authorization
);
export const getCurrentRoute = createSelector(
  (state) => state.currentRoute,
  (currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
  getCurrentRoute,
  (route) => route.title
);
export const getSideMenuState = createSelector(
  (state) => state.app,
  (app) => app.isCollapsedSideMenu
);
export const getCurrentTheme = createSelector(
  (state) => state.currentTheme,
  (currentTheme) => currentTheme
);

/* PROFILE */
export const getUserProfile = createSelector(
  (state) => state.profile,
  (profile) => profile
);
export const currentUserId = createSelector(
  (state) => state.profile,
  (profile) => profile && profile.id
);
export const getCurrentUserRole = createSelector(getUserProfile, (profile) =>
  !profile ? null : (ROLES_RAW.includes(profile.role) ? profile.role : null)
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

/* USERS */
export const getUsers = createSelector(
  (state) => state.users,
  (users) => users
);
export const getUsersForm = createSelector(
  (state) => state.usersForm,
  (form) => form
);

/* ALERT */
export const getAlerts = createSelector(
  (state) => state.alerts,
  (alerts) => alerts
);
export const getAlertsForm = createSelector(
  (state) => state.alertsForm,
  (form) => form
);

/* BEACHS */
export const getBeachs = createSelector(
  (state) => state.beachs,
  (beachs) => beachs
);
export const getBeachsForm = createSelector(
  (state) => state.beachsForm,
  (form) => form
);

/* SERVICES */
export const getServices = createSelector(
  (state) => state.services,
  (services) => services
);
export const getServicesForm = createSelector(
  (state) => state.servicesForm,
  (form) => form
);
export const getServicesBookForm = createSelector(
  (state) => state.servicesBookForm,
  (form) => form
);

/* BOOKINGS */
export const getBookings = createSelector(
    (state) => state.bookings,
    (bookings) => bookings
);
export const getBookingsForm = createSelector(
    (state) => state.bookingsForm,
    (form) => form
);

/* ELEMENTTYPES */
export const getElementtypes = createSelector(
    (state) => state.elementtypes,
    (elementtypes) => elementtypes
);
export const getElementtypesForm = createSelector(
    (state) => state.elementtypesForm,
    (form) => form
);

/* ELEMENTS */
export const getElements = createSelector(
    (state) => state.elements,
    (elements) => elements
);
export const getElementsForm = createSelector(
    (state) => state.elementsForm,
    (form) => form
);

/* WORKORDERS */
export const getWorkorders = createSelector(
    (state) => state.workorders,
    (workorders) => workorders
);
export const getWorkordersForm = createSelector(
    (state) => state.workordersForm,
    (form) => form
);

/* INCIDENCES */
export const getIncidences = createSelector(
    (state) => state.incidences,
    (incidences) => incidences
);
export const getIncidencesForm = createSelector(
    (state) => state.incidencesForm,
    (form) => form
);

/* CUSTOMERFEEDBACKS */
export const getCustomerFeedbacks = createSelector(
    (state) => state.customerFeedbacks,
    (customerFeedbacks) => customerFeedbacks
);
export const getCustomerFeedbacksForm = createSelector(
    (state) => state.customerFeedbacksForm,
    (form) => form
);
