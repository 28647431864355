/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* CUSTOMERFEEDBACK */
export const SET_CUSTOMERFEEDBACK_LIST = "SET_CUSTOMERFEEDBACK_LIST";
export const ADD_NEW_CUSTOMERFEEDBACK = "ADD_NEW_CUSTOMERFEEDBACK";
export const UPDATE_CUSTOMERFEEDBACK = "UPDATE_CUSTOMERFEEDBACK";
export const DELETE_CUSTOMERFEEDBACK = "DELETE_CUSTOMERFEEDBACK";
export const OPEN_CUSTOMERFEEDBACK_FORM = "OPEN_CUSTOMERFEEDBACK_FORM";
export const CLOSE_CUSTOMERFEEDBACK_FORM = "CLOSE_CUSTOMERFEEDBACK_FORM";
export const EDIT_SELECTED_CUSTOMERFEEDBACK = "EDIT_SELECTED_CUSTOMERFEEDBACK";
export const CUSTOMERFEEDBACK_FORM_TOOGLE_LOADING = "CUSTOMERFEEDBACK_FORM_TOOGLE_LOADING";
/* <---- CUSTOMERFEEDBACK ----> */

/* BOOKING */
export const SET_BOOKING_LIST = "SET_BOOKING_LIST";
export const ADD_NEW_BOOKING = "ADD_NEW_BOOKING";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const DELETE_BOOKING = "DELETE_BOOKING";
export const OPEN_BOOKING_FORM = "OPEN_BOOKING_FORM";
export const CLOSE_BOOKING_FORM = "CLOSE_BOOKING_FORM";
export const EDIT_SELECTED_BOOKING = "EDIT_SELECTED_BOOKING";
export const BOOKING_FORM_TOOGLE_LOADING = "BOOKING_FORM_TOOGLE_LOADING";
/* <---- BOOKING ----> */

/* INCIDENCE */
export const SET_INCIDENCE_LIST = "SET_INCIDENCE_LIST";
export const ADD_NEW_INCIDENCE = "ADD_NEW_INCIDENCE";
export const UPDATE_INCIDENCE = "UPDATE_INCIDENCE";
export const DELETE_INCIDENCE = "DELETE_INCIDENCE";
export const OPEN_INCIDENCE_FORM = "OPEN_INCIDENCE_FORM";
export const CLOSE_INCIDENCE_FORM = "CLOSE_INCIDENCE_FORM";
export const EDIT_SELECTED_INCIDENCE = "EDIT_SELECTED_INCIDENCE";
export const INCIDENCE_FORM_TOOGLE_LOADING = "INCIDENCE_FORM_TOOGLE_LOADING";
/* <---- INCIDENCE ----> */

/* WORKORDER */
export const SET_WORKORDER_LIST = "SET_WORKORDER_LIST";
export const ADD_NEW_WORKORDER = "ADD_NEW_WORKORDER";
export const UPDATE_WORKORDER = "UPDATE_WORKORDER";
export const DELETE_WORKORDER = "DELETE_WORKORDER";
export const OPEN_WORKORDER_FORM = "OPEN_WORKORDER_FORM";
export const CLOSE_WORKORDER_FORM = "CLOSE_WORKORDER_FORM";
export const EDIT_SELECTED_WORKORDER = "EDIT_SELECTED_WORKORDER";
export const WORKORDER_FORM_TOOGLE_LOADING = "WORKORDER_FORM_TOOGLE_LOADING";
/* <---- WORKORDER ----> */

/* ELEMENT */
export const SET_ELEMENT_LIST = "SET_ELEMENT_LIST";
export const ADD_NEW_ELEMENT = "ADD_NEW_ELEMENT";
export const UPDATE_ELEMENT = "UPDATE_ELEMENT";
export const DELETE_ELEMENT = "DELETE_ELEMENT";
export const OPEN_ELEMENT_FORM = "OPEN_ELEMENT_FORM";
export const CLOSE_ELEMENT_FORM = "CLOSE_ELEMENT_FORM";
export const EDIT_SELECTED_ELEMENT = "EDIT_SELECTED_ELEMENT";
export const ELEMENT_FORM_TOOGLE_LOADING = "ELEMENT_FORM_TOOGLE_LOADING";
/* <---- ELEMENT ----> */

/* ELEMENTTYPE */
export const SET_ELEMENTTYPE_LIST = "SET_ELEMENTTYPE_LIST";
export const ADD_NEW_ELEMENTTYPE = "ADD_NEW_ELEMENTTYPE";
export const UPDATE_ELEMENTTYPE = "UPDATE_ELEMENTTYPE";
export const DELETE_ELEMENTTYPE = "DELETE_ELEMENTTYPE";
export const OPEN_ELEMENTTYPE_FORM = "OPEN_ELEMENTTYPE_FORM";
export const CLOSE_ELEMENTTYPE_FORM = "CLOSE_ELEMENTTYPE_FORM";
export const EDIT_SELECTED_ELEMENTTYPE = "EDIT_SELECTED_ELEMENTTYPE";
export const ELEMENTTYPE_FORM_TOOGLE_LOADING = "ELEMENTTYPE_FORM_TOOGLE_LOADING";
/* <---- ELEMENTTYPE ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */

/* BEACH */
export const SET_BEACH_LIST = "SET_BEACH_LIST";
export const ADD_NEW_BEACH = "ADD_NEW_BEACH";
export const UPDATE_BEACH = "UPDATE_BEACH";
export const DELETE_BEACH = "DELETE_BEACH";
export const OPEN_BEACH_FORM = "OPEN_BEACH_FORM";
export const CLOSE_BEACH_FORM = "CLOSE_BEACH_FORM";
export const EDIT_SELECTED_BEACH = "EDIT_SELECTED_BEACH";
export const BEACH_FORM_TOOGLE_LOADING = "BEACH_FORM_TOOGLE_LOADING";
/* <---- BEACH ----> */

/* SERVICE */
export const SET_SERVICE_LIST = "SET_SERVICE_LIST";
export const ADD_NEW_SERVICE = "ADD_NEW_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const OPEN_SERVICE_FORM = "OPEN_SERVICE_FORM";
export const CLOSE_SERVICE_FORM = "CLOSE_SERVICE_FORM";
export const EDIT_SELECTED_SERVICE = "EDIT_SELECTED_SERVICE";
export const SERVICE_FORM_TOOGLE_LOADING = "SERVICE_FORM_TOOGLE_LOADING";
export const OPEN_SERVICE_BOOKFORM = "OPEN_SERVICE_BOOKFORM";
export const CLOSE_SERVICE_BOOKFORM = "CLOSE_SERVICE_BOOKFORM";
export const EDIT_SELECTED_BOOKSERVICE = "EDIT_SELECTED_BOOKSERVICE";
export const SERVICE_BOOKFORM_TOOGLE_LOADING = "SERVICE_BOOKFORM_TOOGLE_LOADING";
/* <---- SERVICE ----> */
