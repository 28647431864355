import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_BEACH,
  DELETE_BEACH,
  BEACH_FORM_TOOGLE_LOADING,
  SET_BEACH_LIST,
  UPDATE_BEACH,
} from "../constants";
import { formatBeachs } from "./settingsActionsUtils";

/* BEACH LIST */
export const fetchBeachs = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.beachs.list)
      .then((response) => {
        const beachs = formatBeachs(response.data);
        dispatch({
          type: SET_BEACH_LIST,
          payload: keyBy(beachs, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

/* BEACH LIST */
export const fetchBeachsNoAuth = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.beachs.listNoAuth)
      .then((response) => {
        const beachs = formatBeachs(response.data);
        dispatch({
          type: SET_BEACH_LIST,
          payload: keyBy(beachs, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// BEACHS
export const createBeach = (newBeach) => {
  return async (dispatch) => {
    dispatch({ type: BEACH_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.beachs.create, newBeach)
      .then((response) => {
        const beach = formatBeachs(response.data);
        dispatch({ type: ADD_NEW_BEACH, payload: beach });
        dispatch({ type: BEACH_FORM_TOOGLE_LOADING });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: BEACH_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateBeach = (updatedBeach) => {
  return async (dispatch) => {
    dispatch({ type: BEACH_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.beachs.edit}${updatedBeach?._id}`, updatedBeach)
      .then((response) => {
        const beach = formatBeachs(response.data);
        dispatch({ type: UPDATE_BEACH, payload: beach });
        dispatch({ type: BEACH_FORM_TOOGLE_LOADING });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: BEACH_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteBeachs = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: BEACH_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.beachs.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_BEACH, payload: Ids });
        dispatch({ type: BEACH_FORM_TOOGLE_LOADING });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: BEACH_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
