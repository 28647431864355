import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ELEMENTTYPE,
    DELETE_ELEMENTTYPE,
    ELEMENTTYPE_FORM_TOOGLE_LOADING,
    SET_ELEMENTTYPE_LIST,
    UPDATE_ELEMENTTYPE,
} from "../constants";
import { formatElementtypes } from "./settingsActionsUtils";

/* ELEMENTTYPE LIST */
export const fetchElementtypes = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.elementtypes.list)
            .then((response) => {
                const elementtypes = formatElementtypes(response.data);
                dispatch({
                    type: SET_ELEMENTTYPE_LIST,
                    payload: keyBy(elementtypes, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ELEMENTTYPES
export const createElementtype = (newElementtype) => {
    return async (dispatch) => {
        dispatch({ type: ELEMENTTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.elementtypes.create, newElementtype)
            .then((response) => {
                const elementtype = formatElementtypes(response.data);
                dispatch({ type: ADD_NEW_ELEMENTTYPE, payload: elementtype });
                dispatch({ type: ELEMENTTYPE_FORM_TOOGLE_LOADING });
                // dispatch({
                //     type: SHOW_NOTIFICATION,
                //     payload: {
                //         show: true,
                //         status: "success",
                //         message: "Tipo de elemento creado con éxito.",
                //     },
                // });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: ELEMENTTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateElementtype = (updatedElementtype) => {
    return async (dispatch) => {
        dispatch({ type: ELEMENTTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.elementtypes.edit}${updatedElementtype?._id}`, updatedElementtype)
            .then((response) => {
                const elementtype = formatElementtypes(response.data);
                dispatch({ type: UPDATE_ELEMENTTYPE, payload: elementtype });
                dispatch({ type: ELEMENTTYPE_FORM_TOOGLE_LOADING });
                // dispatch({
                //     type: SHOW_NOTIFICATION,
                //     payload: {
                //         show: true,
                //         status: "info",
                //         message: "Tipo de elemento actualizado.",
                //     },
                // });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: ELEMENTTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteElementtypes = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ELEMENTTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.elementtypes.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ELEMENTTYPE, payload: Ids });
                dispatch({ type: ELEMENTTYPE_FORM_TOOGLE_LOADING });
                // dispatch({
                //     type: SHOW_NOTIFICATION,
                //     payload: {
                //         show: true,
                //         status: "info",
                //         message: "Tipo de elemento eliminado.",
                //     },
                // });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: ELEMENTTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
