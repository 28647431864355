// https://testing-core.deploy.kuarasoftware.es/

export const API = {
  customerFeedbacks: {
    create: `/customerFeedback`,
    edit: `/customerFeedback/`,
    delete: `/customerFeedback/`,
    list: `/customerFeedback`,
  },
  bookings: {
    create: `/booking`,
    edit: `/booking/`,
    unbusy: `/booking/unbusy/`,
    delete: `/booking/`,
    list: `/booking`,
    listAll: `/bookingAll`,
    dates: `/booking/dates/`,
    confirm: `/booking/confirm`,
  },
  incidences: {
    create: `/incidence`,
    edit: `/incidence/`,
    delete: `/incidence/`,
    list: `/incidence`,
  },
  workorders: {
    create: `/workorder`,
    edit: `/workorder/`,
    delete: `/workorder/`,
    list: `/workorder`,
  },
  elements: {
    create: `/element`,
    edit: `/element/`,
    delete: `/element/`,
    list: `/element`,
  },
  elementtypes: {
    create: `/elementtype`,
    edit: `/elementtype/`,
    delete: `/elementtype/`,
    list: `/elementtype`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
  beachs: {
    create: `/beach`,
    edit: `/beach/`,
    delete: `/beach/`,
    list: `/beach`,
    listNoAuth: `/beach/noauth`,
  },
  services: {
    create: `/service`,
    noinbeach: `/service/noinbeach/`,
    edit: `/service/`,
    block: `/service/block/`,
    unblock: `/service/unblock/`,
    delete: `/service/`,
    list: `/service`,
    listnoauth: `/service/noauth`,
  },
};
