export const ROLES = {
  admin: {
    label: "Administrador",
    role: "a2470ee4",
  },
  manager: {
    label: "Encargado",
    role: "a2470ee3",
  },
  operator: {
    label: "Operador",
    role: "a2470ee5",
  },
  client: {
    label: "Cliente",
    role: "a2470ee6",
  },
  maintenance: {
    label: "Mantenimiento",
    role: "a2470ee7",
  },
};

export const ROLES_RAW = Object.values(ROLES).map((r) => r.role);

export const ROLE_OPTS = Object.values(ROLES).map((r) => ({
  label: r.label,
  value: r.role,
}));
